.about.section {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.about .container {
  max-width: 1200px;
  margin: 0 auto;
}

.about .row {
  display: flex;
  align-items: center;
}

.about .img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about .text-body-emphasis {
  color: #333;
}

.about .lead {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.about .list-unstyled {
  padding: 0;
}

.about .list-unstyled li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.about .list-unstyled li i {
  color: #47b2e4;
  margin-right: 10px;
  font-size: 1.2rem;
}

.about .btn {
  margin-top: 20px;
}

.about .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.about .btn-outline-secondary {
  border-color: #6c757d;
  color: #6c757d;
}

.about .btn-outline-secondary:hover {
  background-color: #6c757d;
  color: #fff;
}
