/* ChamadaAcao.css */
.bg-dark {
    background-color: #333;
}

.text-secondary {
    color: #adb5bd;
}

.text-white {
    color: #fff;
}

.px-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.btn-outline-info {
    color: #0dcaf0;
    border-color: #0dcaf0;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #333;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

/* Sobre.css */
.about.section {
    padding: 60px 0;
}

.about .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.about .img-fluid {
    max-width: 100%;
    height: auto;
}

.about .content {
    padding: 20px;
}

.about .content h1 {
    font-size: 2.5rem;
    font-weight: bold;
}

.about .content p {
    font-size: 1.125rem;
    margin: 10px 0;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.about .content ul li i {
    color: #47b2e4;
    margin-right: 10px;
}
