

header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
}

.container-logo {
    display: flex; /* Adicione esta linha */
    justify-content: center; /* Adicione esta linha */
    align-items: center; /* Adicione esta linha */
    width: 100vw;
}

.logo {
    width: 150px;
}
  
  .container-nav-ul{
    display: flex;
    
  }
  
  header ul li{
    margin: 15px;
    list-style: none;
  
  }
  
  /* Telefone */
  @media screen and (max-width: 667px) {
    header{
        align-items: start;
    }
    .container-nav-ul{
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-top: 100px;
        
      }
  }

