.container-slide {
    display: flex;
    width: 100%;
    background-image: url('../../assets/capa.jpg');
    background-size: cover;
    background-position: center;
    margin-top: -20px;
}


.slide-capa {

    display: flex;
    width: 100vw;
    height: 90vh;
    color: #fff;
    font-size: 7vw;
    text-align: center;


}