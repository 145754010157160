body {
  margin: 0;

}

a{
  text-decoration: none;
  color:inherit
}

a:\visited {    color: inherit;}

a {
    color: inherit !important; /* Mantém a cor do elemento pai */
    text-decoration: none !important; /* Remove o sublinhado */
}
